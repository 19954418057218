@import url('https://fonts.googleapis.com/css?family=Quicksand');

body{
  margin: 0;
  font-family: 'Quicksand';
  background-color: black;
  color: white;
  
}
p{
  margin:0;
}
a{
  color: lightcoral;
  font-size: 18px;
  text-decoration: none;
}
h1,h2,h3,h4,h5{
  margin:0;
}
/*-----------navbar--------------*/
@-webkit-keyframes mover {
  0% { transform: translateY(-3px); }
  100% { transform: translateY(3px); }
}
@keyframes mover {
  0% { transform: translateY(-3px); }
  100% { transform: translateY(3px); }
}
.logo{
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1.5s infinite  alternate ease-in-out;
  margin-left: 0;
}
.navbar{
  background-image: transparent;
  font-weight: 600;
  background-image: rgb(22, 22, 22);
  width: 100%;
  height: 70px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-evenly;
  gap: 0px;
}
.navbar li{
  list-style: none;
}
.navbar a:hover{
  color:red;
}
.navbar a{
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  transition: 0.5s;
}
.navbar .links{
  display: flex;
  gap: 3rem;
  margin-right: 100px;
}
.navbar .gitlink{
  margin: 25px;
}
.navbar .togglebtn{
  font-size: 1.5rem;
}
.navbar .togglebtn{
  font-size: 1.5rem;
  cursor:pointer;
  display:none;
}

/*Drop bar*/
.dropnav{
  background-color: black;
  position:absolute;
  right: 150px;
  top: 80px;
  display: none;
  width: 300px;
  border-radius: 10px;
  overflow: hidden;
  height:0;
  transition: height 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.dropnav.open{
  height: 240px;
}
.dropnav li{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem;
  list-style: none;
}
.dropnav a{
  text-decoration: none;
  color: white;
}
@media(max-width: 992px){
  .navbar .links, .navbar .Circlebtn{
    display: none;
  }
  .navbar .togglebtn{
    display: block;
  }
  .dropnav{
    display: block;
    background-color: black;
  }
}
@media(max-width: 576px){
  .dropnav{
    left: 1.5rem;
    width: 90%;
  }
}


/*---------HOME----------*/
.homecontent{
  background-image: url("./Resources/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.welcometext{
  display:flex;
  justify-content: space-evenly;
  align-items:center;
  height: 800px;
}
.welcometext h1{
  font-size: 2.3rem;
}
.welcometext h2{
  font-size: 19px;
  margin:0;
}
.projectbtn{
  text-decoration: none;
  display: inline-block;
  margin-top: 40px;
  padding: 17px;
  border-radius:5px;
  font-size: 17px;
  background-color: rgba(134, 15, 15, 0.384);
  background-size: cover;
  color: white;
  cursor: pointer;
  border:1px solid black;
  transition: 0.5s;
}
.bigsentence{
  display: inline-block;
  margin-top: 100px;
  font-size: 34px;
  font-weight: 600;
  width: 600px;
  height: 150px;
  margin-left: 30px;
  text-align: center;
}
.bigsentence span{
  position: relative;
  font-size: 25px;
  margin-left: -35px;
}
.bigsentence span::before{
  content: "I am Developer.";
  color: #ff7f50;
  text-shadow: 2px 2px 5px rgb(0, 0, 0);
  animation: words 12s infinite, opa 4s infinite;
}
@keyframes opa {
  0%{opacity: 100;}
  100%{opacity: 0;}
}
@keyframes words {
  0%,33%{
    content: "I am Developer.";
    opacity: 1;
  }
  34%,66%{
    content: "Highly motivated to begin my professional career.";
    opacity: 1;
  }
  67%,100%{
    content: "I suggest checking out the interesting projects I've been involved in.";
    opacity: 1;

  }
}
.projectbtn:hover{
  background-color: black;
  color: red;
}
.introcont{
  display: flex;
  justify-content: space-evenly;
  padding: 0 0 30px 0px;
  margin-left: -350px;
}
.intro{
  width: 40%;
}
.intro h1{
  font-size: 50px;
}
.connect{
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 50px;
}
.Circlebtn{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  margin: 10px;
  transition: 0.3s;
}
.Circlebtn:hover{
  color: red;
}
@media(max-width: 992px){
  .welcometext{
    margin-top: 10%;
    margin-left: 30px;
    width: 80%;
    display: inline-block;
  }
  .section2{
    display: flex;
    justify-content: center;
  }
  .compic{
    width: 300px;
  }
  .intro h1{
    font-size: 42px;
  }
  .bigsentence{
    width: 100%;
  }
  .bigsentence span{
    display: inline-block;
    width: 300px;
    height: 150px;
    font-size: 23px;
    margin-left: -60px;
  }
  .intro{
    justify-content: center;
    margin-left: 340px;
  }
  .welcometext h1{
    font-size: 1.3rem;
  }
  .welcometext h2{
    font-size: 1rem;
    margin:0;
  }
}
/*--------Skill----------*/
.skillscontent{
  margin-right: 0px;
  background-image: url("./Resources/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.skillsection h2{
  font-size: 25px;
}
.skills{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 55px;
  width: 60%;
  margin-top: 30px;
  margin-bottom: 50px;
}
.skillcont{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("./Resources/stars.jpg");
  background-size: cover;
  padding: 25px;
  border-radius: 8px;
  border: 1px solid rgb(68, 68, 68);
  box-shadow: 2px 2px 7px 7px rgba(122, 0, 0, 0.308);
  width: 120px;
  height: 120px;
  transition: 0.3s;
}
.skillimg{
  width: 60%;
  transition: 0.1s;
  cursor: pointer;
}
.skillimg:hover{
  width:65%;
  transform: translateY(-10px);
}
.skillcont:hover{
  box-shadow: 2px 2px 7px 7px rgba(163, 0, 0, 0.548);
}
.gitcode{
  margin: 100px 0 100px 0;
}
.gitcodeimg{
  width: 57%;
}
@media(max-width: 992px){
  .skills{
    width: 75%;
  }
  .gitcodeimg{
    width: 90%;
  }
  .skillcont{
    padding: 25px;
    border: 3px solid white;
    width: 40px;
    height: 40px;
  }
  .skillimg{
    width: 120%;
  }
}
@media(max-width: 592px){
  .skills{
    width: 100%;
  }
}

/*--------Projects------*/
.projectscontent{
  background-image: url("./Resources/stars.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.mainprojectcont{
  margin-top: 70px;
  min-height: 710px;
}
.project_title{
  font-size: 2rem;
}
.projects{
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 100px;
}
.compborder{
  width: 350px;
  transition: 0.5s;
  cursor: pointer;
  margin-bottom: 12px;
}
.info{
  margin-top: 17px;
  margin-bottom: 17px;
}
.projectcont{
  border: 1px solid white;
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  transition: opacity 2.5s linear;
  opacity: 0;
  margin-top: 20px;
  box-shadow: 2px 2px 7px 7px rgba(122, 0, 0, 0.308);
  transition: 1s;
}
.name{
  font-size: 27px;
}
.projectcont:hover{
  box-shadow: 2px 2px 7px 7px rgba(163, 0, 0, 0.548);
}
.compborder:hover{
  width: 355px;
}
.contact{
  margin-bottom: 60px;
}
.bannercontainer{
  position: absolute;
  top: 13%;
  left: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
}
.projectbanner{
  position: relative;
  width: 1000px;
  border: 1px solid white;
  border-radius: 15px;
  display: none;
  background-image: url("../src/Resources/bg.png");
}
.bannerexitbtn{
  float: right;
}
.bannerexitpic{
  width: 40px;
  position:absolute;
  right: 30px;
  top: 25px;
  display: inline-block;
  transition: 0.1s linear;
  cursor: pointer;
}
.bannerexitpic:hover{
  top: 20px;
}
.bannerpic{
  margin-top: 15px;
}
.bannername{
  margin-top:30px;
  font-size: 27px;
}
.bannerdet{
  margin: 30px 60px 50px 60px;
  font-size: 25px;
}
@keyframes SettingsZoomin {
    0% { transform: scale(0,  0); }
    100%   { transform: scale(1, 1); }
}
@keyframes SettingsZoomout {
    from {display: block;}
    to   { transform: scale(0,0);}
}
@media(max-width: 1350px) {
  .mainprojectcont{
    margin-top: 30px;
  }
  .projectcont{
    margin-bottom: 50px;
    width: 250px;
    font-size: 12px;
  }
  .compborder:hover{
    width: 255px;
  }
  .compborder{
    width: 250px;
  }
  .bannercontainer{
    top: 18%;
  }
  .projectbanner{
    width: 700px;
  }
  .bannerdet{
    font-size: 15px;
  }
  .bannercompic{
    width: 400px;
  }
}
@media(max-width: 992px) {
  .projectcont{
    margin-top: 20px;
    margin-bottom: 30px;
    width: 300px;
  }
  .compborder{
    width: 300px;
  }
  .bannercontainer{
    display: none;
  }
  .compborder:hover{
    width: 305px;
  }
}
/*----footer-----*/
.footer{
  display:flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(15, 15, 15);
  height: 70px;
}

@media(max-width: 992px){
  .footer{
    padding: 30px 0 40px 0;
    font-size: 17px;
    display: block;
    text-align: center;
  }
}